var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-header-index-wide" },
    [
      _c(
        "a-card",
        {
          style: { height: "100%" },
          attrs: {
            bordered: false,
            bodyStyle: { padding: "16px 0", height: "100%" },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "account-settings-info-main",
              class: _vm.device,
              style: "min-height:" + _vm.mainInfoHeight,
            },
            [
              _c(
                "div",
                { staticClass: "account-settings-info-left" },
                [
                  _c(
                    "a-menu",
                    {
                      style: {
                        border: "0",
                        width: _vm.device == "mobile" ? "560px" : "auto",
                      },
                      attrs: {
                        mode: _vm.device == "mobile" ? "horizontal" : "inline",
                        "default-selected-keys": ["settings"],
                        type: "inner",
                      },
                      on: { openChange: _vm.onOpenChange },
                    },
                    [
                      _c("a-menu-item", { key: "settings" }, [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.settingsClick()
                              },
                            },
                          },
                          [_vm._v("\n              基本设置\n            ")]
                        ),
                      ]),
                      _c("a-menu-item", { key: "security" }, [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.securityClick()
                              },
                            },
                          },
                          [_vm._v("安全设置")]
                        ),
                      ]),
                      _c("a-menu-item", { key: "custom" }, [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.customClick()
                              },
                            },
                          },
                          [_vm._v(" 个性化")]
                        ),
                      ]),
                      _c("a-menu-item", { key: "binding" }, [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.bindingClick()
                              },
                            },
                          },
                          [_vm._v("账户绑定")]
                        ),
                      ]),
                      _c("a-menu-item", { key: "notification" }, [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.notificationClick()
                              },
                            },
                          },
                          [_vm._v("新消息通知")]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "account-settings-info-right" },
                [
                  _c("div", { staticClass: "account-settings-info-title" }, [
                    _c("span", [_vm._v(_vm._s(_vm.title))]),
                  ]),
                  _vm.security ? _c("security", { ref: "security" }) : _vm._e(),
                  _vm.baseSetting
                    ? _c("base-setting", { ref: "baseSetting" })
                    : _vm._e(),
                  _vm.custom ? _c("custom", { ref: "custom" }) : _vm._e(),
                  _vm.notification
                    ? _c("notification", { ref: "notification" })
                    : _vm._e(),
                  _vm.binding ? _c("binding", { ref: "binding" }) : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }